import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major full-height">
        <div className="inner">
            <header className="major">
                <h1>Hello,</h1>
            </header>
            <div className="content">
                <p>
                  We are driven by an unmovable desire to improve ourselves and others.<br />
                  Whale Loop is an incubator for our ideas and projects.
                </p>
                {/* <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Get Started</a></li>
                </ul> */}
            </div>
        </div>
    </section>
)

export default Banner
